export const mintingSectionStyles = {
    section: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '2rem',
      padding: '2rem',
      backgroundColor: '#14141D',
      color: '#fff',
      alignItems: 'center',
    },
    leftSection: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: '#1D1C2B',
      borderRadius: '16px',
      padding: '1.5rem',
    },
    rightSection: {
      padding: '1rem',
    },
    rightSectionHeader:{
      display:"flex",
      flexDirection:"column",
    },
    logo: {
      width: '160px',
      height: '160px',
      borderRadius: '50%',
      objectFit: 'cover',
      marginBottom: '1rem',
    },
    timer: {
      fontSize: '1.2rem',
      color: '#fff',
      marginBottom: '1rem',
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      width: '80%',
    },
    remaining: {
      fontSize: '1rem',
      color: '#ccc',
    },
    price: {
      fontSize: '1rem',
      color: '#ccc',
    },
    quantity: {
      fontSize: '1rem',
      color: '#ccc',
    },
    mintButton: {
      width: '100%',
      marginTop: '1vh',
      padding: '1rem',
      border: '1px solid #fff',
      borderRadius: '8px',
      color: '#fff',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      fontSize: '1rem',
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    title: {
      fontSize: '2rem',
      fontWeight: 'bold',
      marginBottom: '1rem',
    },
    description: {
      fontSize: '1rem',
      color: '#ccc',
      marginBottom: '2rem',
    },
    sectionTitle: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      marginBottom: '1rem',
    },
    itemsContainer: {
      display: 'flex',
      gap: '1rem',
      marginBottom: '2rem',
    },
    itemImage: {
      width: '80px',
      height: '80px',
      borderRadius: '8px',
    },
  };
  
  export const buttonStyles = {
    button: {
      padding: '10px 20px',
      backgroundColor: '#4CAF50',
      color: '#FFFFFF',
      border: 'none',
      borderRadius: '5px',
      fontSize: '16px',
      cursor: 'pointer',
    },
    popup: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#FFFFFF',
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
      zIndex: 1000,
    },
    popupContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '10px',
    },
    walletOption: {
      padding: '10px 20px',
      backgroundColor: '#4CAF50',
      color: '#FFFFFF',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
    },
    noWallets: {
      color: '#888',
      fontSize: '14px',
    },
    closeButton: {
      marginTop: '10px',
      padding: '10px 20px',
      backgroundColor: '#FF0000',
      color: '#FFFFFF',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
    },
    editingPanel: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#FFFFFF',
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
      zIndex: 1000,
    },
  };
  