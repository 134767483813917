export const HeroConfiguration = {
    
    heroOne: {
    type: 'hero',
    children: [
      { type: 'image', content: 'https://via.placeholder.com/1200x600?text=Background' },
      { type: 'heading', content: 'Welcome to Our Website' },
      { type: 'paragraph', content: 'Building a better future together.' },
      { type: 'button', content: 'Get Started' },
    ],
  },
  heroTwo: {
    type: 'hero',
    children: [
      { type: 'heading', content: 'Discover Your Potential' },
      { type: 'paragraph', content: 'Join us today and start making an impact.' },
      { type: 'button', content: 'Join Now' },
    ],
  },
  heroThree: {
    type: 'hero',
    children: [
      { type: 'span', content: 'CAPTION', styles: { fontWeight: 'bold', textTransform: 'uppercase', color: '#64748b' } },
      { type: 'heading', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', styles: { fontSize: '3rem', fontWeight: 'bold', color: '#1e293b' } },
      { type: 'paragraph', content: 'Rhoncus morbi et augue nec, in id ullamcorper at sit.', styles: { fontSize: '1.2rem', lineHeight: '1.6', color: '#475569' } },
      { type: 'button', content: 'Primary Action', styles: { backgroundColor: '#1e40af', color: '#ffffff', padding: '14px 28px', fontWeight: 'bold', borderRadius: '6px', cursor: 'pointer' } },
      { type: 'button', content: 'Secondary Action', styles: { backgroundColor: 'transparent', color: '#1e40af', padding: '12px 24px', border: '2px solid #1e40af', fontWeight: 'bold', borderRadius: '6px', cursor: 'pointer' } },
      { type: 'image', content: 'https://via.placeholder.com/600x400?text=Hero+Image', styles: { width: '100%', maxWidth: '500px', height: 'auto', borderRadius: '8px' } },
    ],
  },}