export const CtaConfigurations = {  
    ctaOne: {
    children: [
      { type: "title", content: "Get Started Today!", styles: { fontSize: "2.5rem", fontWeight: "bold", color: "#1F2937" } },
      { type: "paragraph", content: "Sign up now and take the first step towards a better future.", styles: { fontSize: "1.2rem", color: "#4B5563" } },
      { type: "button", content: "Join Now", styles: { backgroundColor: "#1a1aff", color: "#ffffff", fontSize: "1rem", borderRadius: "8px" } },
      { type: "button", content: "Learn More", styles: { backgroundColor: "transparent", color: "#1a1aff", border: "2px solid #1a1aff", fontSize: "1rem", borderRadius: "8px" } },
      { type: "image", content: "https://via.placeholder.com/300x200?text=CTA+Image", styles: { maxWidth: "300px", borderRadius: "8px" } },
    ],
  },
  
  ctaTwo: {
    children: [
      { type: 'title', content: 'Take Action Now!' },
      { type: 'button', content: 'Primary Action' },
      { type: 'button', content: 'Secondary Action' },
    ],
  },
}