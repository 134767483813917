import { defaultHeroStyles, heroTwoStyles, heroThreeStyles } from '../../../Elements/Sections/Heros/defaultHeroStyles';
import { renderElementToHtml } from '../../htmlRender';

export function renderHero(heroElement, collectedStyles) {
  const { id, configuration, children = [], styles = {} } = heroElement;

  // Determine the correct hero style configuration
  let baseHeroStyles = defaultHeroStyles.hero;
  if (configuration === 'heroTwo') {
    baseHeroStyles = heroTwoStyles.heroSection;
  } else if (configuration === 'heroThree') {
    baseHeroStyles = heroThreeStyles.heroSection;
  }

  // Categorizing children
  let headingHtml = '';
  let descriptionHtml = '';
  let buttonHtmls = [];
  let imageHtml = '';

  children.forEach((child) => {
    const childHtml = renderElementToHtml(child, collectedStyles);

    if (child.type === 'heading' && !headingHtml) {
      headingHtml = child.content; // Store raw content instead of wrapped HTML
    } else if (child.type === 'paragraph' && !descriptionHtml) {
      descriptionHtml = child.content; // Store raw content
    } else if (child.type === 'button') {
      // Assign button styles properly
      const buttonClass = `hero-${id}-button`;
      collectedStyles.push({
        className: buttonClass,
        styles: {
          ...heroTwoStyles.buttonContainer,  // Default button styles
          ...child.styles, // User-defined styles (if any)
        },
      });

      buttonHtmls.push(`<button class="${buttonClass}">${child.content}</button>`);
    } else if (child.type === 'image') {
      imageHtml = childHtml;
    }
  });

  // Merging styles
  const mergedStyles = {
    ...baseHeroStyles,
    ...styles,
    '.heroSection': heroTwoStyles.heroSection,
    '.heroHeading': heroTwoStyles.heading,
    '.heroDescription': heroTwoStyles.description,
  };

  collectedStyles.push({ className: `hero-${id}`, styles: mergedStyles });

  // Final HTML structure for hero section
  return `
    <section class="hero-${id}">
      <div class="heroSection">
        ${headingHtml ? `<h1 class="heroHeading">${headingHtml}</h1>` : ''}
        ${descriptionHtml ? `<p class="heroDescription">${descriptionHtml}</p>` : ''}
        <div class="buttonContainer">
          ${buttonHtmls.join('\n')}
        </div>
      </div>
      <div class="heroImageContainer">${imageHtml}</div>
    </section>
  `.trim();
}
